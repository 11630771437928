export const formConfig = {
  headers: { "Content-Type": "application/x-www-form-urlencoded" }
};
export const formConfigWithToken = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "X-AUTH-TOKEN": localStorage.getItem("token")
  }
};

export function updateHeadersToken(token) {
  localStorage.setItem("token", token);
  formConfigWithToken.headers["X-AUTH-TOKEN"] = token;
  return formConfigWithToken;
}

export function checkPhone(phoneNumber) {
  if (!/^1[3456789]\d{9}$/.test(phoneNumber.replace(/\s/g, ""))) {
    return false;
  }
  return true;
}

export function checkEmail(email) {
  if (
    /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(
      email
    )
  ) {
    return true;
  }
  return false;
}

export function filterArrayEmpty(array) {
  return array.filter(item => item);
}

export function showModalTips() {
  document.getElementsByClassName("modal-tips")[0].style.display = "block";
}

export function getBirthDateByIdentity(idNumber) {
  let birthday =
    idNumber.substring(6, 10) +
    "-" +
    idNumber.substring(10, 12) +
    "-" +
    idNumber.substring(12, 14);
  return birthday;
}

export function getSexByIdentity(idNumber) {
  let sexId = "";
  if (parseInt(idNumber.substr(16, 1)) % 2 === 1) {
    //男
    sexId = "1";
  } else {
    //女
    sexId = "2";
  }
  return sexId;
}

export function checkPasswordComplexity(password) {
  // eslint-disable-next-line
  const reg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{8,64}$/;
  return reg.test(password);
}

export function ChangeAbsHttp(httpAdress) {
  httpAdress = httpAdress.replace(
    "http://gzartsxy.dcampus.com/",
    "/gzartsxycms/"
  );
  httpAdress = httpAdress.replace("https://sites.dcampus.com/pzxyh", "/pzxyh");
  httpAdress = httpAdress.replace(
    "http://xy.gzarts.edu.cn/",
    "http://xy.gzarts.edu.cn/webcms/"
  );
  return httpAdress;
}

export function throttle(fun, delay) {
  let last, deferTimer;
  return function(args) {
    let that = this;
    let _args = arguments;
    let now = +new Date();
    if (last && now < last + delay) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function() {
        last = now;
        fun.apply(that, _args);
      }, delay);
    } else {
      last = now;
      fun.apply(that, _args);
    }
  };
}
