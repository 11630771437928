import React, { Component } from "react";
import { connect } from "react-redux";
import { checkAuth } from "../actions/index";
import { LOGIN_LINK } from "../actions/types";
import { Toast } from "antd-mobile";

export default WrappedComponent => {
  class RequireAuth extends Component {
    componentWillMount() {
      this.props.checkAuth();
    }
    componentWillUpdate(nextProps) {
      if (!nextProps.loading && nextProps.status !== "0000") {
        Toast.fail("请重新登录");
        this.props.history.replace({
          pathname: `${LOGIN_LINK}`,
          state: {
            from: nextProps.location.pathname
          }
        });
      }
    }
    componentWillUnmount() {
      this.setState = (state, callback) => {
        return;
      };
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  function mapStateToProps(state) {
    const { status, error, loading } = state.auth;
    return { status, error, loading };
  }
  return connect(mapStateToProps, { checkAuth })(RequireAuth);
};
