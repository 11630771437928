import { LOGIN_SUCCESS, LOGIN_ERROR } from "../actions/types";

const INITIAL_STATE = {
  error: "",
  loading: true,
  status: ""
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...INITIAL_STATE,
        loading: false,
        status: action.payload.data.status
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: "登录失败",
        status: action.payload.data.status
      };
    default:
      return INITIAL_STATE;
  }
}
