import React, { Component } from "react";
import {
  LOGIN_LINK,
  REGISTER_LINK,
  REGISTER_SUCCESS_LINK,
  REGISTER_PRESUCCESS_LINK,
  REGISTER_SUPPLY_LINK,
  REGISTER_CHOOSE_LINK,
  REGISTER_ERROR_LINK,
  REGISTER_VERIFIED_LINK,
  REGISTER_MANAGER_APPLY_LINK,
  INFO_SUCCESS_LINK,
  INFO_VIEW_LINK,
  INFO_EDIT_LINK,
  PASSWORD_UPDATE_LINK,
  PASSWORD_RESET_LINK,
  PASSWORD_UPDATE_SUCCESS_LINK,
  MAIN_INDEX_LINK,
  MY_MEMBER_LINK,
  MAIN_PERINFO_LINK,
  ACTIVITY_CREATE_LINK,
  ACTIVITY_CREATE_SUCCESS_LINK,
  ACTIVITY_DETAIL_LINK,
  ACTIVITY_LIST_PAGE_LINK,
  ACTIVITY_SIGN_LINK,
  WORK_LIST_PAGE_LINK,
  WORK_DETAIL_LINK,
  DYNAMIC_LIST_PAGE_LINK,
  DYNAMIC_DETAIL_LINK,
  MY_ACTIVITY_LIST_LINK,
  MY_ACTIVITY_NOTICE_LINK,
  MY_ACTIVITY_NOTICE_DETAIL_LINK,
  BANNER_DETAIL_LINK,
  YOUTH_MEMORY_LINK,
  YOUTH_DETAIL_LINK
} from "../actions/types";
import { HashRouter, Route, Switch } from "react-router-dom";
import RequireAuth from "./RequireAuth";

import loadableUtil from "../util/loadable";
const Login = loadableUtil(() => import("./views/Login"));
const Register = loadableUtil(() => import("./views/Resigter/Register.js"));
const RegisterSuccess = loadableUtil(() =>
  import("./views/Resigter/RegisterSuccess")
);
const RegisterPreSuccess = loadableUtil(() =>
  import("./views/Resigter/RegisterPreSuccess")
);
const RegisterError = loadableUtil(() =>
  import("./views/Resigter/RegisterError")
);
const RegisterVerified = loadableUtil(() =>
  import("./views/Resigter/RegisterVerified")
);
const RegisterManagerApply = loadableUtil(() =>
  import("./views/Resigter/RegisterManagerApply")
);
const RegisterSupply = loadableUtil(() =>
  import("./views/Resigter/RegisterSupply")
);
const RegisterChoose = loadableUtil(() =>
  import("./views/Resigter/RegisterChoose")
);

const InfoSuccess = loadableUtil(() => import("./views/Info/InfoSuccess"));
const InfoView = loadableUtil(() => import("./views/Info/InfoView"));
const InfoEdit = loadableUtil(() => import("./views/Info/InfoEdit"));
const PasswordUpdate = loadableUtil(() =>
  import("./views/Password/PasswordUpdate")
);
const PasswordReset = loadableUtil(() =>
  import("./views/Password/PasswordReset")
);
const PasswordUpdateSuccess = loadableUtil(() =>
  import("./views/Password/PasswordUpdateSuccess")
);
const MainIndex = loadableUtil(() => import("./views/Main/MainIndex"));
const MyMember = loadableUtil(() => import("./views/My/MyMember"));
const MainPerinfo = loadableUtil(() => import("./views/Main/MainPerinfo"));
const ActivityDetail = loadableUtil(() =>
  import("./views/Activity/ActivityDetail")
);
const ActivityCreateSuccess = loadableUtil(() =>
  import("./views/Activity/ActivityCreateSuccess")
);
const ActivityCreate = loadableUtil(() =>
  import("./views/Activity/ActivityCreate")
);
const ActivitySign = loadableUtil(() =>
  import("./views/Activity/ActivitySign")
);
const AlumniActivityListPage = loadableUtil(() =>
  import("./views/Main/AlumniActivityListPage")
);
const AlumniWorksListPage = loadableUtil(() =>
  import("./views/Main/AlumniWorksListPage")
);
const AlumniWorkDetail = loadableUtil(() =>
  import("./views/Main/AlumniWorkDetail")
);
const AlumniDynamicDetail = loadableUtil(() =>
  import("./views/Main/AlumniDynamicDetail")
);
const AlumniDynamicListPage = loadableUtil(() =>
  import("./views/Main/AlumniDynamicListPage")
);

const BannerDetail = loadableUtil(() =>
  import("./views/Main/MainBannerDetail")
);

const MyActivityList = loadableUtil(() => import("./views/My/MyActivityList"));
const MyActivityNotice = loadableUtil(() =>
  import("./views/My/MyActivityNotice")
);
const MyActivityNoticeDetail = loadableUtil(() =>
  import("./views/My/MyActivityNoticeDetail")
);
const YouthMemory = loadableUtil(() => import("./views/Main/YouthMemory"));
const YouthMemoryDetail = loadableUtil(() =>
  import("./views/Main/YouthMemoryDetail")
);
class App extends Component {
  render() {
    return (
      <HashRouter>
        <div className="App">
          <Switch>
            <Route path={REGISTER_LINK} component={Register} />
            <Route path={REGISTER_SUCCESS_LINK} component={RegisterSuccess} />
            <Route
              path={REGISTER_PRESUCCESS_LINK}
              component={RegisterPreSuccess}
            />
            <Route path={REGISTER_ERROR_LINK} component={RegisterError} />
            <Route path={REGISTER_VERIFIED_LINK} component={RegisterVerified} />
            <Route
              path={REGISTER_MANAGER_APPLY_LINK}
              component={RegisterManagerApply}
            />
            <Route
              path={REGISTER_SUPPLY_LINK}
              component={RequireAuth(RegisterSupply)}
            />
            <Route
              path={REGISTER_CHOOSE_LINK}
              component={RequireAuth(RegisterChoose)}
            />
            <Route path={INFO_SUCCESS_LINK} component={InfoSuccess} />
            <Route path={INFO_VIEW_LINK} component={RequireAuth(InfoView)} />
            <Route path={INFO_EDIT_LINK} component={RequireAuth(InfoEdit)} />
            <Route
              path={PASSWORD_UPDATE_LINK}
              component={RequireAuth(PasswordUpdate)}
            />
            <Route path={PASSWORD_RESET_LINK} component={PasswordReset} />
            <Route
              path={PASSWORD_UPDATE_SUCCESS_LINK}
              component={RequireAuth(PasswordUpdateSuccess)}
            />
            <Route path={MAIN_INDEX_LINK} component={MainIndex} />
            <Route path={MY_MEMBER_LINK} component={RequireAuth(MyMember)} />
            <Route
              path={MAIN_PERINFO_LINK}
              component={RequireAuth(MainPerinfo)}
            />
            <Route
              path={`${ACTIVITY_DETAIL_LINK}/:activityId`}
              component={RequireAuth(ActivityDetail)}
            />
            <Route
              path={ACTIVITY_CREATE_LINK}
              component={RequireAuth(ActivityCreate)}
            />
            <Route
              path={`${ACTIVITY_LIST_PAGE_LINK}`}
              component={AlumniActivityListPage}
            />
            <Route
              path={`${BANNER_DETAIL_LINK}/link=:link`}
              component={BannerDetail}
            />
            <Route
              path={`${WORK_LIST_PAGE_LINK}`}
              component={AlumniWorksListPage}
            />
            <Route
              path={`${WORK_DETAIL_LINK}/title=:title&pubDate=:pubDate&author=:author&link=:link`}
              component={AlumniWorkDetail}
            />
            <Route
              path={`${DYNAMIC_LIST_PAGE_LINK}`}
              component={AlumniDynamicListPage}
            />
            <Route
              path={`${DYNAMIC_DETAIL_LINK}/link=:link`}
              component={AlumniDynamicDetail}
            />

            <Route path={YOUTH_MEMORY_LINK} component={YouthMemory} />
            <Route
              path={`${YOUTH_DETAIL_LINK}/title=:title&pubDate=:pubDate&author=:author&link=:link`}
              component={YouthMemoryDetail}
            />
            <Route
              path={ACTIVITY_CREATE_SUCCESS_LINK}
              component={RequireAuth(ActivityCreateSuccess)}
            />
            <Route
              path={`${ACTIVITY_SIGN_LINK}/:activityId`}
              component={RequireAuth(ActivitySign)}
            />
            <Route
              path={MY_ACTIVITY_LIST_LINK}
              component={RequireAuth(MyActivityList)}
            />
            <Route
              path={MY_ACTIVITY_NOTICE_LINK}
              component={RequireAuth(MyActivityNotice)}
            />
            <Route
              path={`${MY_ACTIVITY_NOTICE_DETAIL_LINK}/:noticeId`}
              component={RequireAuth(MyActivityNoticeDetail)}
            />
            <Route path={LOGIN_LINK} component={Login} />
          </Switch>
        </div>
      </HashRouter>
    );
  }
}
export default App;
