import { CHECK_TOKEN_URL, LOGIN_SUCCESS, LOGIN_ERROR } from "./types";
import { formConfigWithToken } from "./apis";
import axios from "axios";
//检测登陆
export const checkAuth = () => {
  return dispatch => {
    axios
      .post(CHECK_TOKEN_URL, "", formConfigWithToken)
      .then(function(res) {
        if (res.data.status === "0000") {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res
          });
        } else {
          dispatch({
            type: LOGIN_ERROR,
            payload: res
          });
        }
      })
      .catch(err => {
        dispatch({
          type: LOGIN_ERROR,
          payload: err
        });
      });
  };
};
