export const LOGIN_LINK = "/";
export const REGISTER_LINK = "/register/index";
export const REGISTER_SUCCESS_LINK = "/register/success";
export const REGISTER_PRESUCCESS_LINK = "/register/presuccess";
export const REGISTER_ERROR_LINK = "/register/error";
export const REGISTER_VERIFIED_LINK = "/register/verified";
export const REGISTER_SUPPLY_LINK = "/register/supply";
export const REGISTER_CHOOSE_LINK = "/register/chooseStu";
export const REGISTER_MANAGER_APPLY_LINK = "/register/manager/apply";

export const INFO_SUCCESS_LINK = "/info/success";
export const INFO_VIEW_LINK = "/info/view";
export const INFO_EDIT_LINK = "/info/edit";

export const MAIN_INDEX_LINK = "/main/index";
export const MAIN_PERINFO_LINK = "/main/perinfo";

export const ACTIVITY_CREATE_LINK = "/activity/create";
export const ACTIVITY_CREATE_SUCCESS_LINK = "/activity/createSuccess";
export const ACTIVITY_DETAIL_LINK = "/activity/detail";
export const ACTIVITY_SIGN_LINK = "/activity/sign";

export const BANNER_DETAIL_LINK = "/banner/detail";
export const ACTIVITY_LIST_PAGE_LINK = "/activity/listPage";
export const WORK_LIST_PAGE_LINK = "/work/listPage";
export const WORK_DETAIL_LINK = "/work/detail";
export const DYNAMIC_LIST_PAGE_LINK = "/dynamic/listPage";
export const DYNAMIC_DETAIL_LINK = "/dynamic/detail";

export const YOUTH_MEMORY_LINK = "/youth/memory";
export const YOUTH_DETAIL_LINK = "/youth/detail";

export const MY_ACTIVITY_NOTICE_LINK = "/my/activityNotice";
export const MY_ACTIVITY_NOTICE_DETAIL_LINK = "/my/activityNoticeDetail";
export const MY_ACTIVITY_LIST_LINK = "/my/activityList";
export const MY_MEMBER_LINK = "/my/member";

export const PASSWORD_UPDATE_LINK = "/password/update";
export const PASSWORD_RESET_LINK = "/password/reset";
export const PASSWORD_UPDATE_SUCCESS_LINK = "/password/updateSuccess";

export const LOGIN_URL = "/alumni/login";
export const LOGIN_OUT_URL = "/alumni/logout";
export const CHECK_TOKEN_URL = "/alumni/checkToken";
// 3、注册接口
export const APPLY_MANAGER_AUDIT_URL = "/alumni/register/applyManagerAudit";
export const CHECK_ALUMNI_URL = "/alumni/register/checkAlumni";
export const CHECK_EXIST_ALUMNI_URL = "/alumni/register/checkExistAlumni";
export const EDIT_ALUMNI_INFO_URL = "/alumni/register/editAlumniInfo";
export const EDIT_SCHOOL_RECORD_URL = "/alumni/register/editSchoolRecord";
export const GET_ALUMNI_INFO_URL = "/alumni/register/getAlumniInfo";
export const GET_ALUMNI_STUDENT_URL = "/alumni/register/getValidateAlumni";
export const REGISTER_URL = "/alumni/register/register";
export const FILL_RECORD_URL = "/alumni/register/fillSchoolRecord";

export const GET_ALL_DEPT_URL = "/alumni/getAllDepartment";
export const GET_ALL_SPECIALTY_URL = "/alumni/getAllSpecialty";
export const GET_ALL_DIPLOMA_URL = "/alumni/getAllDiploma";
export const GET_DIPLOMA_URL = "/alumni/getDiploma";

export const GET_DEPT_URL = "/alumni/getDepartment";
export const GET_SPECIALTY_URL = "/alumni/getSpecialty";

export const GET_AREA_URL = "/alumni/getArea";

export const GET_ALL_CLAN_URL = "/alumni/getAllClan";
export const GET_ALL_NATION_URL = "/alumni/getAllNation";
export const GET_ALL_SEX_URL = "/alumni/getAllSex";

export const ACTIVITY_LIST_URL = "/alumni/activity/alumniActivityList";
export const ACTIVITY_DETAIL_URL = "/alumni/activity/alumniActivityDetail";
export const APPLY_ACTIVITY_URL = "/alumni/activity/applyAlumniActivity";
export const MY_APPLY_ACTIVITY_URL = "/alumni/activity/myAlumniActivityList";
// 消息通知
export const MY_ACTIVITY_NOTICE_URL = "/alumni/info/noticeMsgList";
export const MY_ACTIVITY_NOTICE_DETAIL_URL = "/alumni/info/noticeMsgDetail";

export const CANCEL_APPLY_ACTIVITY_URL =
  "/alumni/activity/cancelApplyAlumniActivity";
export const ATTACHMENT_IMG = "/alumni/downloadAttachment";
export const UPLOAD_PHOTO = "/alumni/info/uploadPhoto";

export const ACTIVITY_SIGN_URL = "/alumni/activity/alumniActivityApplySign";

// 校友信息
export const SOCIAL_CIRCLE_URL = "/alumni/info/getSocialCircle";
export const SOCIAL_CIRCLE_INFO_URL = "/alumni/info/getSocialCircleInfoList";

export const UPDATE_PASSWORD_URL = "/alumni/updatePassword";
export const RESET_PASSWORD_VALIDATE_URL = "/alumni/resetPasswordValidate";

export const LOGIN_SUCCESS = "login_success";
export const NO_SESSION = "no_session";
export const LOGIN_ERROR = "login_error";

// cms link

export const CMS_BANNER_XML = "/pzxyh/category/mobile/bannerm/feed/";
export const ART_WORK_XML = "/gzartsxycms/other/gafaaa/artwork/index.xml";
export const DYNAMIC_XML = "/pzxyh/category/zxdt/feed/";
export const MEMORY_XML = "/gzartsxycms/other/gafaaa/memory/index.xml";

export const BANNER_RESOURCE = "/gzartsxycms/other/gafaaa/banner/resource";
export const WORK_RESOURCE = "/gzartsxycms/other/gafaaa/artwork/resource";
export const DYNAMIC_RESOURCE = "/gzartsxycms/other/gafaaa/event/resource";
export const MEMORY_RESOURCE = "/gzartsxycms/other/gafaaa/memory/resource";

// export const CMS_BANNER_XML = "/webcms/gmxyh/xyptydd/banner/index.xml";
// export const ART_WORK_XML = "/webcms/gmxyh/rw/xyzp/index.xml";
// export const DYNAMIC_XML = "/webcms/gmxyh/dt/xygz/index.xml";
// export const MEMORY_XML = "/webcms/gmxyh/xyptydd/memory/index.xml";

// export const BANNER_RESOURCE = "/webcms/gmxyh/xyptydd/banner/resource";
// export const WORK_RESOURCE = "/webcms/gmxyh/rw/xyzp/resource";
// export const DYNAMIC_RESOURCE = "/webcms/gmxyh/dt/xygz/resource";
// export const MEMORY_RESOURCE = "/webcms/gmxyh/xyptydd/memory/resource";
